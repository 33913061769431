* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-family: 'Epilogue', sans-serif;
}
.hidden-scroll::-webkit-scrollbar {
  display: none;
}
.custome-scrolly::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custome-scrolly::-webkit-scrollbar-track {
  background-color: #fff;
}
.custome-scrolly::-webkit-scrollbar-thumb {
  background-color: #ededed;
  border-radius: 10px;
}
.round-loading {
  animation: round-loading 0.5s ease-in-out infinite;
}
@keyframes round-loading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
