.disable-btn {
  pointer-events: none;
  opacity: 0.3;
}
.spin {
  pointer-events: none;
  animation: spin 0.8s linear infinite;
}
@keyframes spin {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}
